import { template as template_c9e383209f7543ca9c8ef8e1e8666e62 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_c9e383209f7543ca9c8ef8e1e8666e62(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
