import { template as template_039550e6835642b4b7b5c4e228136188 } from "@ember/template-compiler";
const FKText = template_039550e6835642b4b7b5c4e228136188(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
