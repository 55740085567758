import { template as template_d48961c39aa24c4ab5232d92dca6244c } from "@ember/template-compiler";
const FKControlMenuContainer = template_d48961c39aa24c4ab5232d92dca6244c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
