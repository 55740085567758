import { template as template_39fb47674a8f4ccca40c041c27d0e343 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_39fb47674a8f4ccca40c041c27d0e343(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
